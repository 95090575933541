export default [
  //   {
  //     title: "Admin",
  //     icon: "UsersIcon",
  //     children: [
  //       {
  //         title: "Admin",
  //         route: "admin",
  //       },
  //       {
  //         title: "Role",
  //         route: "role",
  //       },
  //     ],
  //   },
  {
    title: "Administrator",
    route: "admin",
    icon: "UsersIcon",
  },
  {
    title: "Role",
    route: "role",
    icon: "SettingsIcon",
  },
  {
    title: "User",
    route: "user",
    icon: "UsersIcon",
  },
  {
    title: "Contribution",
    route: "contributor",
    icon: "FileTextIcon",
  },
  {
    title: "Company",
    route: "company",
    icon: "DatabaseIcon",
  },

  {
    title: "Content",
    icon: "FileTextIcon",
    children: [
      // {
      //   title: "Language",
      //   route: "language",
      // },
      {
        title: "How We Work",
        route: "how-we",
      },

      {
        title: "Content Contribution",
        route: "content-contribute",
      },
      {
        title: "Content About Us",
        route: "content-about-us",
      },
      {
        title: "Privacy Policy",
        route: "content-privacy",
      },
      // {
      //   title: "Intellectual Property",
      //   route: "content-intelektual",
      // },

      // {
      //   title: "Company List",
      //   route: "content-company",
      // },
    ],
  },

  // {
  //   title: "Third Party Provider",
  //   route: "third-driver",
  //   icon: "FileTextIcon",
  // },
];
